@import "mixins"
.simpleSideBar
  width: 59px
  min-width: 59px
  border-right: 1px solid var(--scroll_border)
  position: relative
  z-index: 100

  > div
    height: 100%

  :global(.loader)
    width: 50px
    > span
      font-size: 16px

.header
  color: var(--base_mainTabActiveColor)
  position: relative
  z-index: 1
  @include flex
  justify-content: center
  width: calc(100% - 9px)
  height: var(--simpleSidebar_HeaderHeight)
  font-size: var(--simpleSidebar_HeaderFontSize)
  text-transform: var(--base_textTransform)
  @include bold
  border-radius: var(--multiview_toolRadius) var(--multiview_toolRadius) 0 0
  border-bottom: var(--simpleSidebar_HeaderBorder)
  background: var(--base_mainTabActiveBg)
  box-shadow: var(--base_shadowForScroll)

.topLeagues,.topMatchesBtn, .upcomingBtn
  background: var(--simpleSidebar_topLeauguesBg)
  color: var(--simpleSidebar_itemColor)
  height: 56px
  border-top: var(--sidebar_topLeauguesBorderTop)
  border-bottom: var(--sidebar_topLeauguesBorderBottom)
  @include flex
  font-size: 9px
  text-transform: uppercase
  flex-direction: column
  justify-content: center
  text-align: center
  border-radius: var(--multiview_toolRadius)
  margin-top: var(--simpleSidebar_itemMargin)
  line-height: 11px
  @include pointer
  position: relative
  :global(.svg-icon)
    margin-bottom: 6px

  &:hover
    color: rgb(var(--secondaryColor))
    background: rgba(var(--simpleSidebar_openedBg), 0.8)

.topLeagues
  .favoriteStar
    @include flex
    margin-bottom: 6px
    mask-image: var(--icon-favorite)
    min-width: 16px
    height: 16px
    background: var(--star_favoriteActiveFill)

  .liveTopLeaguesIcon
    margin-top: 6px
    display: flex
    margin-bottom: 5px
    img
      transition: opacity 0.8s !important

  .count
    top: 2px

  &.open
    background: rgb(var(--simpleSidebar_openedBg))
    border-top-color: var(--simpleSidebar_openedBorderTop)
    color: rgb(var(--secondaryColor))

    .count
      background: var(--simpleSidebar_openedCountBg)
      color: var(--simpleSidebar_openedCountColor)

.upcomingBtn
  font-size: 8px
  :global(.svg-icon)
    svg
      width: auto
      height: 16px
      path
        fill: #e8cf74!important

  &.active
    background: var(--sidebar_upcomingBtnBg)
    color: rgb(var(--secondaryColor))
    border-top-color: var(--simpleSidebar_openedBorderTop)
    :global(.svg-icon)
      svg
        path
          fill: rgb(var(--secondaryColor))!important

.topMatchesBtn
  :global(.svg-icon)
    svg
      width: auto
      height: 16px
      path
        fill: #e8cf74!important

  &.active
    background: var(--sidebar_topMatchesBtnBg)
    color: rgb(var(--secondaryColor))
    border-top-color: var(--simpleSidebar_openedBorderTop)
    :global(.svg-icon)
      svg
        path
          fill: rgb(var(--secondaryColor))!important

.timeFilter
  padding: 0 8px
  background: var(--simpleSidebar_bg)
  @include flex
  margin: var(--sidebar_groupTitleMargin)
  justify-content: center
  border-radius: var(--multiview_toolRadius)
  border-top: 1px solid var(--simpleSidebar_borderTop)
  border-bottom: 1px solid var(--simpleSidebar_borderBottom)
  height: 32px

  > span
    border: 1px solid var(--simpleSidebar_timeFilterborderColor)
    color: var(--simpleSidebar_openedCountColor)
    background: var(--simpleSidebar_openedCountBg)
    border-radius: 2px
    min-width: 42px
    height: 22px
    @include flex
    @include bold
    text-transform: uppercase
    font-size: 11px
    padding: 2px 3px 1px 5px

    &:after
      content: ""
      @include arrowIcon
      background-color: var(--base_mainTabActiveColor)
      transform: rotate(-90deg)
      margin: 2px 0 0 auto
      transform-origin: top

  &:hover
    @include pointer

    span
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.4)

  &.opened span
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.6)

.rightSide
  margin-top: calc(var(--simpleSidebar_HeaderHeight) * -1)
  flex: 1 100%

.scrollableContent
  padding-top: var(--simpleSidebar_HeaderHeight)
  padding-bottom: 20px
  min-height: 100%
  padding-right: 9px !important

  + div
    border-right: 0
    width: 9px

.sportsList > li
  display: flex
  justify-content: center
  flex-direction: column
  border-radius: var(--multiview_toolRadius)
  border-top: 1px solid var(--simpleSidebar_borderTop)
  border-bottom: 1px solid var(--simpleSidebar_borderBottom)
  min-height: 57px
  color: var(--simpleSidebar_itemColor)
  background: var(--simpleSidebar_bg)
  margin-top: var(--simpleSidebar_itemMargin)

  > div
    position: relative
    margin-bottom: 6px
    @include flex
    justify-content: center

  :global(.svg-sport-icon)
    min-width: 22px
    height: 22px
    background: var(--simpleSidebar_borderBottom)
    @include flex
    justify-content: center
    overflow: hidden
    border-radius: 100%
    box-shadow: var(--sidebar_gameCountShadow)

    > span
      height: 16px

    svg
      path
        fill: var(--simpleSidebar_itemColor)!important

  &:hover, &.opened, &.selected
    border-top-color: var(--simpleSidebar_openedBorderTop)
    color: rgb(var(--secondaryColor))

    svg
      path
        fill: rgb(var(--secondaryColor))!important

  &:hover
    @include pointer
    background: rgba(var(--simpleSidebar_openedBg), var(--sidebar_sportIconOpacity))
    .count
      background: rgb(var(--secondaryColor))

  &.opened
    background: rgb(var(--simpleSidebar_openedBg))

    .count
      background: var(--simpleSidebar_openedCountBg)
      color: var(--simpleSidebar_openedCountColor)

  &.selected :global(.svg-sport-icon) svg
    path
      fill: var(--sidebar_sportItemSelectedIconFill) !important

.count
  min-width: 16px
  padding: 2px 2px 0
  height: 11px
  display: flex
  align-items: center
  border-radius: 2px
  position: absolute
  z-index: 1
  justify-content: center
  background: var(--simpleSidebar_itemColor)
  color: #21262c
  box-shadow: 0 0 2px 0 #1c1c1c
  font-size: 9px
  right: 4px
  top: -6px

.sportName
  font-size: 10px
  @include ellipsis
  padding: 0 4px
  text-transform: uppercase
  text-align: center
